<template>
  <div class="container">
    <h3 class="text-center my-4">Business Profile</h3>
    <div class="row flex justify-content-center ">
      <div class="col-md-8 rounded-3xl bg-color border-2 p-3">
        <div class="form-group">
          <label for="fullName">Businness Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Business Name"
            disabled
            v-model="formData.businessname"
          />
        </div>
        <div class="form-group">
          <label for="businessType">Businness Type</label>
          <select disabled class="form-control" v-model="formData.businesstype">
            <option selected value="">Select business type</option>
            <option
              v-for="bus in businessType"
              :selected="formData.businesstype == 1 ? 'Hospital' : 'Pharmacy'"
              :key="bus.id"
              :value="bus.id"
            >
              {{ bus.fullName }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="email"> Business Email</label>
          <input
            type="email"
            class="form-control"
            placeholder="Enter your email"
            v-model="formData.businessemail"
            disabled
          />
        </div>

        <div class="form-row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="OfficePhone"> Buiness Phone Number</label>
              <input
                type="text"
                placeholder="Business Phone Number"
                class="form-control text-left"
                v-model="formData.businessphone"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="businessAddress_1">Businness Address </label>
          <input
            required
            type="text"
            class="form-control"
            placeholder="Enter Businness  Address"
            v-model="formData.businessaddress"
          />
        </div>

        <div class="form-group">
          <label for="companyWebsite"> Business Website</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter your Business Website url"
            v-model="formData.businessWebsite"
          />
        </div>

        <div class="form-group">
          <label for="businessPhone">Businness Registration Number</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter Businness Reg Number"
            v-model="formData.businessRegNum"
          />
        </div>

        <div class="form-group">
          <label for="numberOfEmployees"> Number Of Employees</label>
          <input
            type="text"
            v-model="formData.numemp"
            placeholder="Enter no of employees"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="search text-">Street Address</label>
          <input
            disabled
            type="text"
            placeholder="chaple street, abura"
            class="form-control"
            v-model="formData.streetaddress"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["formData"],
  data() {
    return {
      businessType: {}
    };
  },

  mounted() {
    this.GetRequiredData();
  },

  methods: {
    GetRequiredData() {
      this.$store.dispatch("healthhub/GetRequiredData").then((response) => {
        this.businessType = response.data.item2;
      });
    }
  }
};
</script>

<style scoped>
.bg-color {
  color: #fff;
  background: #0c4369 !important;
}

select:disabled,
input:disabled {
  background-color: #e6e3e1;
}
</style>
