<template>
  <div class="container">
    <h3 class="text-center my-4">Business Documents</h3>
    <div class="row flex justify-content-center ">
      <div class="col-md-8 rounded-3xl border-2 bg-color">
        <div
          class="
            max-w-md
            mx-auto
            bg-color
            rounded-lg
            overflow-hidden
            md:max-w-lg
          "
        >
          <div class="md:flex">
            <div class="w-full">
              <div class="p-3">
                <div v-if="url" id="preview">
                  <div class="d-flex justify-content-center">
                    <img :src="url" />
                  </div>
                  <br />

                  <div class="upload-btn-wrapper mt-2 d-flex justify-content-center">
                    <button class="btn ">change Logo</button>
                    <input
                      @change="handleImage"
                      type="file"
                      name="myfile"
                      id="myfile"
                      accept="image/png, image/gif, image/jpeg"
                    />
                  </div>
                </div>

                <div v-else class="mb-2">
                  <span>Upload Business Logo</span>

                  <div
                    class="
                      relative
                      h-40
                      rounded-lg
                      border-dashed border-2 border-gray-200
                      bg-white
                      flex
                      justify-center
                      items-center
                      hover:cursor-pointer
                    "
                  >
                    <div class="absolute">
                      <div class="flex flex-col items-center">
                        <i class="fa fa-cloud-upload fa-3x text-gray-200"></i>

                        <span class="block text-oonpayprimary font-normal">{{
                          logoTitle ? logoTitle : "Browse files"
                        }}</span>
                      </div>
                    </div>
                    <input
                      type="file"
                      class="h-full w-full opacity-0"
                      name=""
                      @change="handleImage"
                      accept="image/*"
                    />
                  </div>
                  <div class="flex justify-between items-center text-gray-400">
                    <span>Accepted file type:.png, jpg,jpeg only</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="my-5 py-5  p-4">
          <p>Upload Corporate Affairs Document</p>
          <div class="custom-file mb-5">
            <input
              type="file"
              @change="uploadBusDocs"
              class="custom-file-input"
              accept=".doc,.pdf,.txt,.ppt"
            />
            <label class="custom-file-label">{{
              docTitle ? docTitle : "Choose file..."
            }}</label>
          </div>
          <p>Upload Other Registration Documents</p>
          <div class="custom-file">
            <input
              type="file"
              @change="uploadOtherBusDoc"
              class="custom-file-input"
              accept=".doc,.pdf,.txt,.ppt"
            />
            <label class="custom-file-label">{{
              businessotherdocTitle ? businessotherdocTitle : "Choose file..."
            }}</label>
            <div class="invalid-feedback">
              Example invalid custom file feedback
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["formData"],
  data() {
    return {
      businessLogo: null,
      logoTitle: null,
      url: this.formData.url || null,
      businesscorporateaffairdoc: this.formData.businesscorporateaffairdoc || null,
      docTitle: this.formData.docTitle || null,
      businessotherdoc: null,
      businessotherdocTitle: this.formData.businessotherdocTitle || null
    };
  },
  methods: {
    handleImage(e) {
      this.businessLogo = e.target.files[0];
      this.logoTitle = this.businessLogo.name;
      this.url = URL.createObjectURL(this.businessLogo);
      this.formData.businessLogo = this.businessLogo;
      this.formData.url = URL.createObjectURL(this.businessLogo);
    },
    uploadBusDocs(e) {
      this.businesscorporateaffairdoc = e.target.files[0];
      this.docTitle = this.businesscorporateaffairdoc.name;
      this.formData.businesscorporateaffairdoc = this.businesscorporateaffairdoc;
      this.formData.docTitle = this.businesscorporateaffairdoc.name;
    },
    uploadOtherBusDoc(e) {
      this.businessotherdoc = e.target.files[0];
      this.businessotherdocTitle = this.businessotherdoc.name;
      this.formData.businessotherdoc = this.businessotherdoc;
      this.formData.businessotherdocTitle = this.businessotherdocTitle

    }
  }
};
</script>

<style lang="scss" scoped>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid #f2813e;
  color: white;
  background-color: #f2813e;
  padding: 8px 20px;
  border-radius: 8px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.bg-color {
  color: #fff;
  background: #0c4369 !important;
}
</style>
