<template>
  <div class="container">
    <div class="row flex justify-content-center">
      <div class="col-md-8 rounded-3xl bg-color border-2">
        <div class="row d-flex justify-content-center my-3">
          <div class="col-md-12  rounded-2xl mr-2 text-center ">
            <h5 class="text-white">Create Your Business</h5>
            <h3 class="text-yellow-500 uppercase">PERMTAG /STORE TAG</h3>
            <form class="my-10">
              <div class="form-group">
                <input
                  type="search"
                  class="form-control"
                  name="contact"
                  v-model="formData.businesspermtag"
                  placeholder="e.g #HOPECLINIC"
                />
              </div>

              <button
                @click.prevent="checkPermTag"
                class="btn btn-small px-5 rounded-2xl btn-warning"
              >
                Check PERMTAG Availability
              </button>
            </form>
            <small class="text-white my-10"
              >Perm Uniquely identifies your business on Oonpay. It cant be
              changed after creation!
            </small>
          </div>
        </div>
        <div class="row d-flex justify-content-center my-3">
          <div class="col-md-12  rounded-2xl py-3">
            <form>
              <div class="form-row">
                <div class="col-md-6 mb-3">
                  <label for="BusinessContact">Admin Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.adminfirstname"
                    placeholder="first Name"
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <label class="opacity-0" for="LastName">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.adminlastname"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="Contact Person">Admin Email</label>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  v-model="formData.adminemail"
                  placeholder="Email"
                />
              </div>
              <div class="form-group">
                <label for="OfficePhone"> Admin Phone</label>
                <input
                  type="button"
                  data-toggle="modal"
                  data-target="#businesPhonModalCenter"
                  class="form-control text-left"
                  v-model="formData.adminphonenumber"
                />
              </div>

              <!-- Modal -->
              <div
                class="modal fade"
                id="businesPhonModalCenter"
                tabindex="-1"
                role="dialog"
                aria-labelledby="businesPhonModalCenterTitle"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content pb-5">
                    <div class="modal-header">
                      <h5 class="modal-title" id="businesPhonModalLongTitle">
                        Verify Business Phone
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body space-y-5">
                      <div v-if="show_confirm == 1">
                        <div class="row flex justify-content-center">
                          <div class="col-md-10">
                            <div class="form-group">
                              <label for="countries">Select country code</label>
                              <select
                                @change="updateCountryCode"
                                class="form-control"
                                name="countries"
                              >
                                <option selected>select country code</option>

                                <option
                                  v-for="code in all_countries"
                                  :key="code.index"
                                  :value="code.callCode"
                                >
                                  {{ code.callCode }}
                                </option>
                              </select>
                            </div>
                            <div class="form-group">
                              <input
                                type="number"
                                v-model="adminphonenumber"
                                placeholder="enter phone number"
                                class="form-control"
                              />
                            </div>

                            <button
                              @click.prevent="sendCode"
                              class="btn btn-block btn-primary"
                            >
                              send code
                            </button>
                          </div>
                        </div>
                      </div>
                      <div v-if="show_confirm == 2">
                        <div class="row flex justify-content-center">
                          <div class="col-md-10">
                            <div class="form-group">
                              <input
                                type="text"
                                placeholder="xxxxxx"
                                class="form-control"
                                v-model="confirm_otp"
                              />
                            </div>
                            <div class="d-flex justify-content-between">
                              <button
                                @click="goBack"
                                type="button"
                                class="btn btn-primary"
                              >
                                go back
                              </button>
                              <button
                                @click.prevent="verifyCode"
                                class="btn  btn-primary"
                              >
                                verify
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group formControl">
                <label for="Create Password">Admin Password</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="formData.adminpassword"
                  name="password"
                  placeholder="**************"
                />
                <small class="text-white"
                  >Must be at least 8 characters and contain a lowercase
                  character, uppercase character and a number.</small
                >
              </div>
              <div class="form-group formControl">
                <label for="Re-Enter Password">Re-Enter Password</label>
                <input
                  type="password"
                  v-model="formData.confirmpassword"
                  class="form-control"
                  name="password"
                  placeholder="*************"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
export default {
  props: ["formData"],
  data() {
    return {
      otp: null,
      confirm_otp: null,

      phonenumber: null,
      selected_code: null,
      show_confirm: 1,
      adminphonenumber:"",
      businesspermtag: null
    };
  },

  methods: {
    goBack() {
      this.show_confirm = 1;
    },

    validations() {
      if (this.adminpassword.length < 6) {
        this.error.push("Pass must be at least 6 characters long");
      }
    },
    updateCountryCode(e) {
      this.selected_code = e.target.value;
    },

    //Check PermTag Availability
    checkPermTag() {
      const data = {
        permtag: this.formData.businesspermtag
      };
      this.$store
        .dispatch("healthhub/CheckPermTagAvialabiity", data)
        .then((response) => {
          if (response.data == true) {
            this.$toasted.error("PermTag Already Exist. Create new one");
          }
          this.$toasted.success(
            "PermTag Available. Continue to create admin account",
            { duration: 5000 }
          );
        });
    },

    //Send OTP
    sendCode() {
      
      if (this.adminphonenumber.charAt(0) == 0 || this.adminphonenumber.charAt(0) == 1) {
          var newPhone = this.adminphonenumber.substring(1);
        }else{
          newPhone = this.adminphonenumber
        }
      // let newPhone = this.adminphonenumber.substring(1);
      let main_number = `${this.selected_code}${newPhone}`;

      const data = {
        code: this.selected_code,
        phonenumber: main_number
      };

      this.phonenumber = main_number;
      this.$store
        .dispatch("healthhub/VerifyAdminPhone", data)
        .then((response) => {
          if (response.data == true) {
            this.show_confirm = 2;
            this.otp = response.data;
            (this.selected_code = ""), (this.adminphonenumber = "");
          }
          this.formData.adminphonenumber = main_number;
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            title: "Error",
            text: err.response.data
          });
        });
    },

    // Verify Phone code
    verifyCode() {
      this.$store
        .dispatch("healthhub/verifyOTP", this.confirm_otp)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            localStorage.setItem("adminPhoneNumberGUID", response.data);
            $("#businesPhonModalCenter").modal("hide");
            this.show_confirm = 1;
            this.confirm_otp = "";
            this.$store.dispatch(
              "healthhub/updateMainPhoneNumber",
              this.phonenumber
            );
            this.$toasted.success("phone number confirmed successfully", {
              duration: 5000
            });
          }
        });
    }
  },

  mounted() {
    this.$store.dispatch("dashboard/GetAllCountries");
  },

  computed: {
    ...mapGetters("dashboard", ["all_countries"])
  }
};
</script>

<style scoped>
.bg-color {
  background: #0c4369 !important;
}

label {
  color: #fff;
}

.formControl.error input {
  border-color: red;
}
</style>
