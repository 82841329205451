<template>
  <div class="bg-main ">
    <component :is="currentStepComponent" :formData="formData" />
    <div class="row d-flex justify-content-center my-3">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6" v-if="currentStep > 1">
            <button class="btn btn-primary btn-block  btn-lg" @click="prevStep">
              Previous
            </button>
          </div>
          <div class="col-md-6" v-if="currentStep < totalSteps">
            <button class="btn btn-info btn-block btn-lg" @click="nextStep">
              Next
            </button>
          </div>
          <div class="col-md-6" v-if="currentStep === totalSteps">
            <button
              class="btn btn-warning btn-block btn-lg"
              @click="submitForm"
            >
              <b-spinner v-if="loading" type="grow"></b-spinner>
              <span v-else>Complete Profile</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepOne from "./multistepform/BusinessReg.vue";
import StepThree from "./multistepform/AdminReg.vue";
import StepTwo from "./multistepform/uploadDocs.vue";

const localstore = JSON.parse(localStorage.getItem("merchant_info"));

export default {
  data() {
    return {
      loading: false,
      currentStep: 1,
      totalSteps: 3,
      formData: {
        businessname: localstore.businessName,
        businessemail: localstore.businessEmailAddress,
        businessphone: localstore.businessPhoneNumber,
        businessaddress: localstore.businessAddress,
        numemp: localstore.businessNumberOfEmployees,
        streetaddress: localstore.businessLocation?.streetAddress,
        businessRegNum: localstore.businessRegistrationNumber,
        businessWebsite: localstore.businessWebsite,
        businesstype: localstore.businessTypeId,
        businesscountryId: localstore.businessLocation?.countryId,
        city: localstore.businessLocation?.city,
        latitude: localstore.businessLocation?.latitude,
        longitude: localstore.businessLocation?.longitude,
        guid: localstore.guid
      }
    };
  },
  computed: {
    currentStepComponent() {
      switch (this.currentStep) {
        case 1:
          return StepOne;
        case 2:
          return StepTwo;
        case 3:
          return StepThree;
        default:
          return StepOne;
      }
    }
  },
  methods: {
    nextStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep++;
      }
     
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    submitForm() {
      this.loading = true;

      if (!this.formData.businessLogo) {
        this.$toasted.error("Please upload logo", {
          duration: 5000
        });
        return false;
      }

      if (!this.formData.businesscorporateaffairdoc) {
        this.$toasted.error("Please upload business document", {
          duration: 5000
        });
        return false;
      }
      // Handle form submission
      // console.log("Form submitted with data:", this.formData);

      let Data = new FormData();

      Data.append("businessname", this.formData.businessname);
      Data.append("businesstypeid", this.formData.businesstype);
      Data.append("businessaddress", this.formData.businessaddress);
      Data.append("businesslocation", this.formData.city);
      Data.append("businessnumberOfemployees", this.formData.numemp);
      Data.append("businessWebsite", this.formData.businessWebsite);
      Data.append("businesspermtag", this.formData.businesspermtag);
      Data.append("businessemail", this.formData.businessemail);
      Data.append("businessphonenumber", this.formData.businessphone);
      Data.append("businessregistrationnumber", this.formData.businessRegNum);
      Data.append("adminfirstname", this.formData.adminfirstname);
      Data.append("adminlastname", this.formData.adminlastname);
      Data.append("adminphonenumber", this.formData.adminphonenumber);
      Data.append("adminemail", this.formData.adminemail);
      Data.append("adminpassword", this.formData.adminpassword);
      Data.append("businessLogo", this.formData.businessLogo);
      Data.append(
        "businesscorporateaffairdoc",
        this.formData.businesscorporateaffairdoc
      );
      Data.append("businessotherdoc", this.formData.businessotherdoc);
      Data.append("businesscountryId", this.formData.businesscountryId);
      Data.append("businesscity", this.formData.city);
      Data.append("businesslatitude", this.formData.latitude);
      Data.append("businesslongitude", this.formData.longitude);
      Data.append("businessstreetAddress", this.formData.streetaddress);
      Data.append("GUID", this.formData.guid);

      // for (var pair of Data.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      this.$store
        .dispatch("healthhub/CreateMerchantAccount", Data)
        .then((response) => {
          if (response.status == 200) {
            this.$router.push("/profile-complete");
            this.loading = false;
            localStorage.clear();
          }
        })
        .catch((err) => {
          this.modalActive = true;
          this.loading = false;
          err.response.data.map((res) => {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: res.error
            });
          });
        });
    }
  }
};
</script>

<style scoped>
.bg-color {
  background: #0c4369 !important;
}

html {
  margin: 0;
  background: #f4f8fb;
}
</style>
